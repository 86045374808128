<template>
  <loading
    :active="isLoadingModel"
    :can-cancel="true"
    color="#ffc107"
    :is-full-page="fullPage"
  ></loading>

  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h5 style="color: brown"><b>TEST RESULT (JEE/NEET)</b> </h5>
        <Toast />
        <Toolbar>
          <template #start>
            <Dropdown
              style="width: 300%"
              id="status"
              v-model="submissionstatus"
              :options="submissionItems"
              :value="submissionItems.value"
              optionLabel="name"
              placeholder="Batch Year"
              :class="{ 'p-invalid': submitted && !selectstatus }"
              required="true"
              @change="get_course_list"
            ></Dropdown>

            &nbsp;
            <Dropdown
              style="width: 300%"
              id="status"
              v-model="coursestatus"
              :options="courseItems"
              :value="courseItems.value"
              optionLabel="name"
              placeholder="Test Date"
              :class="{ 'p-invalid': submitted && !selectstatus }"
              required="true"
              @change="get_list"
            ></Dropdown>
          </template>

          <template #end>
            <!-- <Button
              label="Export"
              icon="pi pi-upload"
              class="p-button-help"
              @click="exportCSV($event)"
            /> -->
          </template>
        </Toolbar>

        <div class="grid">
          <div class="col-12 lg:col-6 xl:col-3">
            <div class="card mb-0">
              <div class="flex justify-content-between mb-3">
                <div>
                  <span class="block text-500 font-medium mb-3"
                    >No Of Student Appear</span
                  >
                  <div class="text-900 font-medium text-xl"><b style="color:green">{{ TNL }}</b></div>
                </div>
                <div
                  class="
                    flex
                    align-items-center
                    justify-content-center
                    bg-blue-100
                    border-round
                  "
                  style="width: 2.5rem; height: 2.5rem"
                >
                  <i class="pi pi-fw pi-thumbs-up text-blue-500 text-xl"></i>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 lg:col-9">
            <h5><b>Download Question & Solutions</b> </h5>
            <hr />
            <Button
              v-if="maths"
              label="Math Solution"
              class="p-button-rounded p-button-secondary"
              @click="view_video(maths)"
              style="font-weight:bold"
            />&nbsp;
            <Button
              v-if="physics"
              label="Physics Solution"
              class="p-button-rounded p-button-success"
              @click="view_video(physics)"
              style="font-weight:bold"
            />&nbsp;
            <Button
              v-if="chemistry"
              label="Chemistry Solution"
              class="p-button-rounded p-button-info"
              @click="view_video(chemistry)"
              style="font-weight:bold"
            />&nbsp;
            <Button
              v-if="botony"
              label="Botany Solution"
              class="p-button-rounded p-button-warning"
              @click="view_video(botony)"
              style="font-weight:bold"
            />&nbsp;
            <Button
              v-if="zoology"
              label="Zoology Solution"
              class="p-button-rounded p-button-danger"
              @click="view_video(zoology)"
              style="font-weight:bold"
            />
            <hr />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="grid">
    <div class="col-12 lg:col-3">
      <div class="card">
        <DataTable
          :value="botonyproduct"
          style="font-size: 10px"
          showGridlines
          responsiveLayout="scroll"
          rows="10"
        > <template #empty>
        <b style="text-align:center">No records found.</b>
      </template>
          <template #header>
            <div
              class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              "
            >
              <h5 class="m-0"><b>Top 10 Botany</b> </h5>
            </div>
          </template>

          <Column
            header="Student Name"
            style="min-width: 3rem"
            :field="columns[0]"
          >
            <template #body="{ data }">
              {{ data.studenT_NAME }}
            </template>
          </Column>
          <Column header="Score" style="min-width: 1rem;text-align: right;" :field="columns[0]">
            <template #body="{ data }">
              {{ data.totaL_SCORE }}
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <div class="col-12 lg:col-3">
        <div class="card">
        <DataTable
          :value="chemistryproduct"
          style="font-size: 10px"
          showGridlines
          responsiveLayout="scroll"
          rows="10"
        ><template #empty>
        <b style="text-align:center">No records found.</b>
      </template>
          <template #header>
            <div
              class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              "
            >
              <h5 class="m-0"><b>Top 10 Chemistry</b>
</h5>
            </div>
          </template>

          <Column
            header="Student Name"
            style="min-width: 3rem"
            :field="columns[0]"
          >
            <template #body="{ data }">
              {{ data.studenT_NAME }}
            </template>
          </Column>
          <Column header="Score" style="min-width: 3rem;text-align: right;" :field="columns[0]">
            <template #body="{ data }">
              {{ data.totaL_SCORE }}
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <div class="col-12 lg:col-3">
        <div class="card" >
        <DataTable
          :value="mathsproduct"
          style="font-size: 10px"
          showGridlines
          responsiveLayout="scroll"
          rows="10"
        ><template #empty>
        <b style="text-align:center">No records found.</b>
      </template>
          <template #header>
            <div
              class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              "
            >
              <h5 class="m-0"><b>Top 10 Maths</b></h5>
            </div>
          </template>

          <Column
            header="Student Name"
            style="min-width: 3rem"
            :field="columns[0]"
          >
            <template #body="{ data }">
              {{ data.studenT_NAME }}
            </template>
          </Column>
          <Column header="Score" style="min-width: 3rem;text-align: right;" :field="columns[0]">
            <template #body="{ data }">
              {{ data.totaL_SCORE }}
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
     <div class="col-12 lg:col-3">
        <div class="card" >
        <DataTable
          :value="physicsproduct"
          style="font-size: 10px"
          showGridlines
          responsiveLayout="scroll"
          rows="10"
        ><template #empty>
        <b style="text-align:center">No records found.</b>
      </template>
          <template #header>
            <div
              class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              "
            >
              <h5 class="m-0"><b>Top 10 Physics</b></h5>
            </div>
          </template>

          <Column
            header="Student Name"
            style="min-width: 3rem"
            :field="columns[0]"
          >
            <template #body="{ data }">
              {{ data.studenT_NAME }}
            </template>
          </Column>
          <Column header="Score" style="min-width: 3rem;text-align: right;" :field="columns[0]">
            <template #body="{ data }">
              {{ data.totaL_SCORE }}
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <hr>

    <div class="col-12 lg:col-4">
        <div class="card" >
        <DataTable
          :value="zoologyproduct"
          style="font-size: 10px"
          showGridlines
          responsiveLayout="scroll"
          rows="10"
        ><template #empty>
        <b style="text-align:center">No records found.</b>
      </template>
          <template #header>
            <div
              class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              "
            >
              <h5 class="m-0"><b>Top 10 Zoology</b></h5>
            </div>
          </template>

          <Column
            header="Student Name"
            style="min-width: 3rem"
            :field="columns[0]"
          >
            <template #body="{ data }">
              {{ data.studenT_NAME }}
            </template>
          </Column>
          <Column header="Score" style="min-width: 3rem;text-align: right;" :field="columns[0]">
            <template #body="{ data }">
              {{ data.totaL_SCORE }}
            </template>
          </Column>
        </DataTable>
      </div>
    </div>

        <div class="col-12 lg:col-4">
        <div class="card" >
        <DataTable
          :value="JEEproduct"
          style="font-size: 10px"
          showGridlines
          responsiveLayout="scroll"
          rows="10"
        ><template #empty>
        <b style="text-align:center">No records found.</b>
      </template>
          <template #header>
            <div
              class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              "
            >
              <h5 class="m-0"><b>Top 10 Over All JEE</b> </h5>
            </div>
          </template>

          <Column
            header="Student Name"
            style="min-width: 3rem"
            :field="columns[0]"
          >
            <template #body="{ data }">
              {{ data.studenT_NAME }}
            </template>
          </Column>
          <Column header="Score" style="min-width: 3rem;text-align: right;" :field="columns[0]">
            <template #body="{ data }">
              {{ data.totaL_SCORE }}
            </template>
          </Column>
        </DataTable>
      </div>
    </div>


        <div class="col-12 lg:col-4">
        <div class="card" >
        <DataTable
          :value="NEETproduct"
          style="font-size: 10px"
          showGridlines
          responsiveLayout="scroll"
          rows="10"
        ><template #empty>
        <b style="text-align:center">No records found.</b>
      </template>
          <template #header>
            <div
              class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              "
            >
              <h5 class="m-0"><b>Top 10 Over All NEET</b> </h5>
            </div>
          </template>

          <Column
            header="Student Name"
            style="min-width: 3rem"
            :field="columns[0]"
          >
            <template #body="{ data }">
              {{ data.studenT_NAME }}
            </template>
          </Column>
          <Column header="Score" style="min-width: 3rem;text-align: right;" :field="columns[0]">
            <template #body="{ data }">
              {{ data.totaL_SCORE }}
            </template>
          </Column>
        </DataTable>
      </div>
    </div>

     <div class="col-12 lg:col-6">
        <div class="card" >
        <DataTable
           :value="NEETproductAll"
            style="font-size: 10px"
            :paginator="true"
            class="p-datatable-gridlines"
            :rows="5"
            dataKey="id"
            :rowHover="true"
            v-model:filters="filters1"
            filterDisplay="menu"
            :loading="loading1"
            :filters="filters1"
            responsiveLayout="scroll"
            :totalRecords="NEETproductAllL"
        >
          <template #header>
            <div
              class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              "
            >
              <h5 class="m-0"><b>All NEET</b></h5>
            </div>
          </template>

          <Column
            header="Student Name"
            style="min-width: 3rem"
            :field="columns[0]"
          >
            <template #body="{ data }">
              {{ data.studenT_NAME }}
            </template>
          </Column>
          <Column header="Score" style="min-width: 3rem;text-align: right;" :field="columns[0]">
            <template #body="{ data }">
              {{ data.totaL_SCORE }}
            </template>
          </Column>
        </DataTable>
      </div>
    </div>


<div class="col-12 lg:col-6">
        <div class="card" >
        <DataTable
          :value="JEEproductAll"
            style="font-size: 10px"
            :paginator="true"
            class="p-datatable-gridlines"
            :rows="5"
            dataKey="id"
            :rowHover="true"
            v-model:filters="filters1"
            filterDisplay="menu"
            :loading="loading1"
            :filters="filters1"
            responsiveLayout="scroll"
            :totalRecords="JEEproductAllL"
        >
          <template #header>
            <div
              class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              "
            >
              <h5 class="m-0"><b>All NEET</b> </h5>
            </div>
          </template>

          <Column
          
            header="Student Name"
            style="min-width: 3rem;"
            :field="columns[0]"
          >
            <template #body="{ data }">
              {{ data.studenT_NAME }}
            </template>
          </Column>
          <Column header="Score" style="min-width: 3rem;text-align: right;" :field="columns[0]">
            <template #body="{ data }">
              {{ data.totaL_SCORE }}
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import ProductService from "../service/ProductService";
//import FormNewsMaster from "@/components/FormNewsMaster";
//import * as Survey from "survey-vue";

// import axios from "axios";
import apis from "@/apis";
import axios from "axios";

export default {
  data() {
    return {
      botony: "",
      chemistry: "",
      maths: "",
      physics: "",
      zoology: "",

       NEETproductAllL:0,
      JEEproductAllL:0,

      NEETproductAll:null,
      JEEproductAll:null,
      NEETproduct:null,
      JEEproduct:null,
      botonyproduct: null,
      chemistryproduct: null,
      mathsproduct: null,
      physicsproduct: null,
      zoologyproduct: null,

      from_date: new Date(),
      to_date: new Date(),
      uploadproductDialog: false,
      stdDialog: false,
      submissionItems: [],
      submissionstatus: { name: "", value: "" },
      courseItems: [],
      coursestatus: { name: "", value: "" },
      isLoading: false,
      isLoadingModel: false,
      loading: false,
      fullPage: true,
      file_attachmentsm: "",
      file_attachmentdpp: "",
      file_attachmentdppA: "",
      dropdownItems: [
        { name: "YES", value: "YES" },
        { name: "NO", value: "NO" },
      ],

      stdproducts: null,
      products: null,
      TNL: 0,
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      lazyParams: {},
      product: {},
      selectedProducts: null,
      filters: {},
      offset: 1,
      selectstatus: { name: "", value: "" },
      selectstatus2: { name: "Motivational", value: "Motivational" },
      limit: 10,
      columns: [],
      head: [
        "Data Usages",
        "Network Connectivity",
        "Students",
        "Teachers",
        "Student Registration",
        "News Master",
        "Government Panel",
        "Feedback Master",
        "User Queries",
        "Schemes Master",
        "Notice Master",
        "Subject Master",
        "Teacher Schedule Master",
        "Teacher Schedule DE Master",
        "Query Types Master",
        "Sub Query Types Master",
        "Admin Users",
        "Application Roles",
        "Departments",
        "Cast Master",
        "Year Master",
        "District Master",
        "Student Attendance",
        "Student Document",
        "Teachers",
        "Radical ids",
        "Subject Topics",
        "Query Comments",
        "Student Submit Answers",
        "Mock Tests",
        "Mock Test Papers",
        "Mock Test Results",
        "Video",
        "Syllabus",
        "Course",
      ],
      page_no: 1,
      submitted: false,
      totalRecords: 0,
      id: "",
      statuses: [
        { label: "INSTOCK", value: "instock" },
        { label: "LOWSTOCK", value: "lowstock" },
        { label: "OUTOFSTOCK", value: "outofstock" },
      ],
    };
  },
  productService: null,
  async created() {
    this.productService = new ProductService();
    this.initFilters();
    this.get_submission_dropdown();
    //this.get_course_list();
  },
  async mounted() {
    // this.productService.getProducts().then(data => this.products = data);
    this.id = this.$route.params.id;

    this.lazyParams = {
      first: 0,
      rows: this.limit,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    if (this.totalRecords == 0) {
      await this.get_count();
    }
    await this.get_list();
    //alert(this.id);
  },
  watch: {
    async $route() {
      this.products = [];
      this.columns = [];
      this.totalRecords = 0;
      this.id = this.$route.params.id;
      if (this.id) {
        await this.get_count();
        this.get_list();
      }
    },
  },
  methods: {
    view_video(video) {
      // alert(video)
      window.open(video, "_blank");
    },
    view_std(info) {
      var data = {
        TSCH_DE_ID: info.tscH_DE_ID,
      };
      this.loading = true;
      var promise = apis.lectureattendedstudentlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        this.stdproducts = response.data;
        this.stdDialog = true;
      });
    },
    get_course_list: function () {
      var data = {
        YEAR: this.submissionstatus.value,
      };
      this.isLoadingModel = true;
      var promise = apis.TestDateList(data, this.id);
      promise.then((response) => {
        this.isLoadingModel = false;
        console.log(response);

        var info = response.data;
        if (info) {
          this.courseItems = [];
          for (var i = 0; i < info.length; i++) {
            this.courseItems.push({
              name: info[i].d,
              value: info[i].r,
            });
          }
        }
      });
    },

    get_submission_dropdown: function () {
      var data = {
        //  "TEACHER_ID":parseInt(localStorage.getItem("id"))
        Limit: 100,
        page_no: this.page_no,
        count: false,
      };
      this.loading = true;
      var promise = apis.yearlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);

        var info = response.data;
        if (info) {
          this.submissionItems = [];
          for (var i = 0; i < info.length; i++) {
            this.submissionItems.push({
              name: info[i].exaM_YEAR,
              value: info[i].enrollmenT_YEAR,
            });
          }
        }
      });
    },
    //a simple date formatting function
    dateFormat(inputDate, info) {},
    handleFileUploadsm() {
      this.file_attachmentsm = this.$refs.file_attachmentsm.files[0];

      //alert(this.$refs.file_attachmentsm.files[0].name);
    },
    handleFileUploaddpp() {
      this.file_attachmentdpp = this.$refs.file_attachmentdpp.files[0];

      //alert(this.$refs.file_attachmentdpp.files[0].name);
    },
    handleFileUploaddppA() {
      this.file_attachmentdppA = this.$refs.file_attachmentdppA.files[0];

      //alert(this.$refs.file_attachmentdppA.files[0].name);
    },
    async onPage(event) {
      this.lazyParams = event;
      this.page_no = event.page + 1;
      this.limit = event.rows;
      // console.log(event);
      await this.get_count();
      if (this.totalRecords > 0) {
        this.get_list();
      }
    },
    get_count: function () {
      var data = {
        TEST_DATE: this.coursestatus.value,
      };
      this.loading = true;
      var promise = apis.subjecttestdetails(data, this.id);
      promise.then((response) => {
        this.loading = false;

        this.botony = response.data[0].botony;
        this.chemistry = response.data[0].chemistry;
        this.maths = response.data[0].maths;
        this.physics = response.data[0].physics;
        this.zoology = response.data[0].zoology;
      });
    },
    refresh() {
      this.product = null;
      this.submissionstatus = { name: "", value: "" };
    },
    async get_list() {
      var data = {
        TEST_DATE: this.coursestatus.value,
      };
      this.isLoadingModel = true;
      var promise = apis.TopTenTestResultListAllSubject(data, this.id);
      promise.then((response) => {
        this.isLoadingModel = false;
        console.log(response);
        this.products = response.data;
        this.TNL = this.products.studencount;

        this.botonyproduct = this.products.botany;
        this.chemistryproduct = this.products.chemistry;
        this.mathsproduct = this.products.maths;
        this.physicsproduct = this.products.physics;
        this.zoologyproduct = this.products.zoology;

        this.NEETproduct = this.products.neet;
        this.JEEproduct = this.products.jee;


        this.NEETproductAll = this.products.neetAll;
        this.JEEproductAll = this.products.jeeAll;
        this.JEEproductAllL=this.JEEproductAll.length;
        this.NEETproductAllL=this.NEETproductAll.length;

        console.log(this.products[0]);
        if (this.columns.length == 0) {
          if (this.products.length > 0) {
            this.columns = Object.keys(this.products[0]);
          }
        }
      });
      await this.get_count();
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    openNew() {
      this.product = {};
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    async Updatesubmission() {
      this.submitted = true;
      if (!this.selectstatus.value) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please select check by teacher",
          life: 3000,
        });
        return false;
      }
      if (!this.product.teacheR_REMARK) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Remark",
          life: 3000,
        });
        return false;
      }

      //edit
      if (this.product.tscH_DE_ID) {
        var data = {
          TSCH_DE_ID: this.product.tscH_DE_ID,
          STUDENT_ID: this.product.studenT_ID,
          TEACHER_REMARK: this.product.teacheR_REMARK,
          TEACHER_CHECK: this.selectstatus.value,
        };
        this.isLoadingModel = true;
        var promise = apis.updateteacherremark(data);
        promise
          .then((responseapi) => {
            this.$swal(responseapi.data.message);
            this.isLoadingModel = false;
            this.productDialog = false;
            this.get_list();
            this.get_count();
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.response.data);
            this.get_list();
            this.get_count();
          });
      }

      this.selectstatus = { name: "", value: "" };
    },
    saveProduct() {
      this.submitted = true;
      if (this.product.name.trim()) {
        if (this.product.id) {
          this.product.inventoryStatus = this.product.inventoryStatus.value
            ? this.product.inventoryStatus.value
            : this.product.inventoryStatus;
          this.products[this.findIndexById(this.product.id)] = this.product;
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Updated",
            life: 3000,
          });
        } else {
          this.product.id = this.createId();
          this.product.code = this.createId();
          this.product.image = "product-placeholder.svg";
          this.product.inventoryStatus = this.product.inventoryStatus
            ? this.product.inventoryStatus.value
            : "INSTOCK";
          this.products.push(this.product);
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Created",
            life: 3000,
          });
        }
        this.productDialog = false;
        this.product = {};
      }
    },
    editProduct(product) {
      this.product = { ...product };
      this.productDialog = true;
      // this.selectstatus.value = this.product.type;
      // this.selectstatus.name = this.product.type;

      // this.selectstatus2.value = this.product.status;
      // this.selectstatus2.name = this.product.status;

      // this.file_attachmentsm = this.product.image;
    },
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct() {
      this.products = this.products.filter((val) => val.id !== this.product.id);
      this.deleteProductDialog = false;
      this.product = {};
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Product Deleted",
        life: 3000,
      });
    },

    deleteVideo() {
      if (this.product.id) {
        var data = {
          Id: this.product.id,
        };
        this.isLoadingModel = true;
        var promise = apis.deletebatchmaster(data);
        promise.then((responseapi) => {
          this.isLoadingModel = false;
          if (responseapi.status == 200) {
            // this.hideDialog1();
            this.deleteProductDialog = false;
            this.$swal(responseapi.data);
            this.get_list();
            this.get_count();
          } else {
            this.$swal("error to Delete Video");
          }
        });
      }
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      this.products = this.products.filter(
        (val) => !this.selectedProducts.includes(val)
      );
      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Products Deleted",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
@import "../assets/demo/badges.scss";
</style>
